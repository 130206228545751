import { useEffect, useRef } from 'react'
import { Link } from 'gatsby'
import Logo from '~/components/nav/common_logo_wh.svg'
import Arrow from '~/images/common_icon_arrow_r.svg'
import { Seo } from '~/components'
import * as s from '~/components/pages/wrap/wrap-order.module.scss'
import Data from '~/json/wrap-plans.json'
import { ShopifyBuyInit } from '../../lib/utils'

const ApplyPage = () => {
  const loadingRef = useRef()
  function loadingInit() {
    loadingRef.current.classList.remove('is-visible')
  }

  useEffect(() => {
    if (
      !window.location.href.includes('order') ||
      typeof window === 'undefined'
    )
      return

    loadingInit()

    Data.plans.forEach(({ buyId, elementId }) =>
      ShopifyBuyInit({
        buyId,
        elementId,
        type: 'product',
        caption: false,
      })
    )
  }, [])

  return (
    <>
      <Seo title="order" pageUrl="order" />
      <div
        ref={loadingRef}
        id="loadingArea"
        className="orderLoading is-visible"
      >
        <div className="loadingContent">
          <div>
            <div role="img" aria-label="Loading">
              <Logo />
            </div>
            <div className="loadingBar" />
          </div>
        </div>
      </div>

      <div id="wrap_order_page" className="pageComponent">
        <header className="titleComponent contentComponent">
          <h1 className="heading pageTitle">ORDER</h1>
        </header>
        <nav className="contentComponent">
          <ul className={`${s.breadcrumbComponent}  mincho`}>
            <li>
              <Link to="/wrap" itemProp="url">
                TOP
              </Link>{' '}
              <span>
                <Arrow />
              </span>
            </li>
            <li>ご注文プラン選択</li>
          </ul>
        </nav>
        <div className={`${s.wrap_order_wrapper} contentComponent`}>
          <header>
            <h2 className={`${s.wrap_order_heading} heading is-secondary`}>
              スニーカーラップ
            </h2>
            <p>
              スニーカーラップ・オゾン除菌＋靴内乾燥
              <br />
              カビ予防・加水分解予防効果あり
            </p>
          </header>
          <div className={s.shopify_buy__orders}>
            {Data.plans.map(({ elementId, title, price, points }) => (
              <section key={elementId} className={s.wrap_order_section}>
                <header>
                  <h2 className="heading is-secondary">{title}</h2>
                  <p>{points[0].text}</p>
                  <span className="shopify-buy__product__price">{price}</span>
                </header>
                <div id={elementId} />
              </section>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}
export default ApplyPage
